<template>
  <section class="-bg-white three-images-links">
    <div class="container-fluid px-md-5 text-center">
      <div class="row">
        <Observer class="col-12 text-center content" name="fade-up">
          <h2 class="h4 title -upper" v-if="item.title" v-text="item.title"></h2>
          <h3 class="h6 subtitle" v-if="item.subtitle" v-text="item.subtitle"></h3>
          <div class="h6 wysiwyg" v-if="item.text" v-html="item.text"></div>
        </Observer>
        <hooper class="col-12" :settings="hooperSettings" @slide="updateProgress" @updated="updateSlider" tabindex="-1" :class="{'no-slide': item.images.length < itemsToShow}" ref="carousel">
          <slide v-for="(image, i) in item.images" :key="i" :index="i">
            <Observer name="fade-up">
              <router-link :to="localizedUrl + image.link" v-if="image.link && !image.link.includes('http')" class="hooper-slide__inner">
                <lazy-picture :item="image" desktop-ratio="1.25" mobile-ratio="1.25"></lazy-picture>
                <span class="link-light" v-if="image.text" v-text="image.text"></span>
              </router-link>
              <a :href="image.link" v-else-if="image.link && image.link.includes('http')" class="hooper-slide__inner">
                <lazy-picture :item="image" desktop-ratio="1.25" mobile-ratio="1.25"></lazy-picture>
                <span class="link-light" v-if="image.text" v-text="image.text"></span>
              </a>
              <div v-else class="hooper-slide__inner">
                <lazy-picture :item="image" desktop-ratio="1.25" mobile-ratio="1.25"></lazy-picture>
                <span class="link-light" v-if="image.text" v-text="image.text"></span>
              </div>
            </Observer>
          </slide>
        </hooper>
      </div>
    </div>
  </section>
</template>

<script>
import { Hooper, Slide} from 'hooper'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { ReadyHook } from 'vue-asyncready'
import LazyPicture from './utils/LazyPicture.vue'

export default {
  props: ['item', 'items', 'pagePosition', 'siblings-components', 'enter'],
  name: 'ThreeImagesLinks',
  mixins: [ReadyHook],
  mounted() { },
  components: {
    LazyPicture,
    Hooper,
    Slide
  },
  data() {
    return {
      currentSlide: 0,
      itemsToShow: 1.25,
      hooperSettings: {
        initialSlide: 0,
        itemsToShow: 1.25,
        centerMode: false,
        transition: 400,
        autoPlay: false,
        mouseDrag: false,
        shortDrag: true,
        touchDrag: true,
        hoverPause: false,
        keysControl: true,
        wheelControl: false,
        trimWhiteSpace: true,
        breakpoints: {
          768: {
            itemsToShow: 3
          }
        }
      }
    }
  },
  methods: {
    updateSlider(payload) {
      this.itemsToShow = payload.settings.itemsToShow
    },
    updateProgress(payload) {
      this.currentSlide = payload.currentSlide
    }
  },
  computed: {
    localizedUrl() {
      return currentStoreView().url || ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';

section.three-images-links {
  position: relative;
  overflow: hidden;
  padding: 0 0 17rem;
  text-align: center;

  @include bp(max md) {
    padding: 0 0 4rem;
  }
}

.hooper {
  margin: 4.8rem 0 0;
  padding: 0;
  height: auto;
  outline: none;

  /deep/ .hooper-slide {
    position: relative;
    outline: none;
    height: auto;
    padding: 1rem 1rem;

    &__inner {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .lazy-picture {
        flex: 1;
      }
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    .link-light {
      margin: 2rem 0 0;
      font-weight: 400;
      font-size: em(16.8);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    & > .obs {
      height: 100%;
      transform: translateY(0);

      &.obs-fade-up {
        transform: translateY(10rem);
      }
    }
  }
}
</style>
